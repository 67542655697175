import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Certificates.scss";
import { images } from "../../constants";

const Certificates = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [certificates, setCcertificates] = useState([]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    var allCertificates = [
      {
        _id: 1,
        order: 1,
        name: "Microsoft Certified: Azure Fundamentals",
        imgurl: images.microsoft,
        certificationLink:
          "https://www.credly.com/badges/16e37e49-e837-4638-8a75-cd01153843a0/public_url",
        detail:
          "Earners of the Azure Fundamentals certification have demonstrated foundational level knowledge of cloud services and how those services are provided with Microsoft Azure.",
        organisation: "Microsoft",
        _type: "certificates",
      },
      {
        _id: 2,
        order: 2,
        name: "Exam 480: Programming in HTML5 with JavaScript and CSS3",
        imgurl: images.microsoft,
        certificationLink:
          "https://www.credly.com/badges/e19eafb8-396e-4b45-b7f4-de83af1af292/linked_in_profile",
        detail:
          "Passing Exam 480: Programming in HTML5 with JavaScript and CSS3 validates a candidate’s ability to access and secure data as well as implement document structures, objects, and program flow.",
        organisation: "Microsoft",
        _type: "certificates",
      },
      {
        _id: 3,
        order: 3,
        name: "Create a Subnet",
        imgurl: images.cybrary,
        certificationLink: "",
        detail:
          "Convert from Decimal to Hexadecimal to Binary Know the Classful IPv4 network ranges Subnet an IPv4 and IPv6 network Utilize VLSM to “consume” an IP space.",
        organisation: "Cybrary",
        _type: "certificates",
      },
      {
        _id: 4,
        order: 4,
        name: "NDG Linux Unhatched - 2019 EN",
        imgurl: images.ndg,
        certificationLink: "",
        detail: "Understand the basics of the Linux Command Line Interface.",
        organisation: "Network Development Group",
        _type: "certificates",
      },
    ];

    setCcertificates(allCertificates);
  }, []);

  return (
    <>
      {certificates.length > 0 && (
        <>
          <div className="app__certificates-item app__flex">
            <img
              src={certificates[currentIndex].imgurl}
              alt={certificates[currentIndex].name}
            />
            <div className="app__certificates-content">
              <p className="p-text">{certificates[currentIndex].detail}</p>
              <div>
                {certificates[currentIndex].certificationLink === "" ? (
                  <h4 className="bold-text">
                    {certificates[currentIndex].name}
                  </h4>
                ) : (
                  <a
                    style={{ textDecoration: "none" }}
                    href={certificates[currentIndex].certificationLink}
                  >
                    <h4 className="bold-text">
                      {certificates[currentIndex].name}
                    </h4>
                  </a>
                )}
                <h5 className="p-text">
                  {certificates[currentIndex].organisation}
                </h5>
              </div>
            </div>
          </div>

          <div className="app__certificates-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? certificates.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>

            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === certificates.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Certificates, "app__certificates"),
  "certificates",
  "app__primarybg"
);
