import React from "react";

import { AppWrap, MotionWrap } from "../../wrapper";
import Progress from "../../components/ProgressBar/Progress";
import "./About.scss";

const About = () => {
  return (
    <div className="app__about-info">
      <p className="p-text-about">About Me</p>
      <div className="aboutPage">
        <div className="aboutMeSection">
          <h2 className="head-text">
            Following <span>The Path</span> To The Best Possible{" "}
            <span>Software</span>
          </h2>

          <p className="p-text" style={{ marginTop: "2rem" ,textTransform: "capitalize"}}>
            An <span>Enthusiastic Software Engineer</span> constantly pushing
            myself harder to improve and make a difference in the Computer
            Software Industry.
          </p>
        </div>

        <div className="app__profiles">
          <Progress
            className="app__progress"
            value={95}
            max={100}
            progressValue="95%"
            progressName="Website &amp; Web Applications"
          />
          <Progress
            className="app__progress"
            value={60}
            max={100}
            progressValue="60%"
            progressName="Mobile Applications"
          />
          <Progress
            className="app__progress"
            value={90}
            max={100}
            progressValue="90%"
            progressName="Software Design &amp; Architecture"
          />
          <Progress
            className="app__progress"
            value={85}
            max={100}
            progressValue="85%"
            progressName="Databases"
          />
        </div>
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
