import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { BsYoutube, BsWhatsapp } from "react-icons/bs";
import { FaGithub,FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { images } from "../../constants";
import "./Navbar.scss";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            className="sidebar"
            animate={{ x: [240, 0] }}
            transition={{ duration: 0.45, ease: "easeOut" }}
          >
            <HiX className="hix" onClick={() => setToggle(false)} />
            <ul>
              {[
                "home",
                "about",
                "portofolio",
                "experiences",
                "certificates",
                "contact",
              ].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <div className="sidebar__social">
              <a href="https://www.linkedin.com/in/gertian-mici-2102">
                <div>
                  <FaLinkedinIn />
                </div>
              </a>
              <a href="https://www.youtube.com/channel/UC5dUMasCAjPZSXk0YsWM6sw"
              >
                <div>
                  <BsYoutube />
                </div>
              </a>
              <a href="https://wa.link/onrx9m">
                <div>
                  <BsWhatsapp />
                </div>
              </a>
              <a href="https://github.com/GertianMici">
                <div>
                  <FaGithub />
                </div>
              </a>
              <a href="instagram://user?username=gent.mici">
                <div>
                  <FaInstagram />
                </div>
              </a>
            </div>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
