import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Portofolio.scss";

const Portofolio = () => {
  const [projects, setProjects] = useState([]);
  const [filterProject, setFilterProject] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    var allProjects = [
      {
        codeLink: "https://github.com/GertianMici/BuildingSecurity",
        description:
          "This application is a model of a real life building security that requires a person to identity himself and display Tag to enter a building.",
        imgUrl: images.webApp,
        order: 0,
        projectLink: "https://github.com/GertianMici/BuildingSecurity",
        tags: ["Web App"],
        title: "Building Security",
        _id: "1",
      },
      {
        codeLink: "https://github.com/GertianMici/NetXception",
        description:
          "A simple library class that extends Exception model to allow upserting a list of values against a key in the Data property.",
        imgUrl: images.nuget,
        order: 0,
        projectLink: "https://www.nuget.org/packages/NetXception",
        tags: ["Nuget"],
        title: "NetXception",
        _id: "2",
      },
      {
        codeLink: "https://github.com/GertianMici/NetExContext",
        description:
          "An implementation of Entity framework Db Context to provide meaningful exceptions.",
        imgUrl: images.nuget,
        order: 0,
        projectLink: "https://www.nuget.org/packages/NetExContext",
        tags: ["Nuget"],
        title: "NetExContext",
        _id: "3",
      },
      {
        codeLink: "https://github.com/GertianMici",
        description:
          "A simple mobile application that allows sending Post Request to a server and receiving a response.",
        imgUrl: images.mobileApp,
        order: 0,
        projectLink: "https://github.com/GertianMici",
        tags: ["Mobile App"],
        title: "Apiater",
        _id: "4",
      }
    ];

    setProjects(allProjects);
    setFilterProject(allProjects);
  }, []);

  const handleProjectFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterProject(projects);
      } else {
        setFilterProject(
          projects.filter((portofolio) => portofolio.tags.includes(item))
        );
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        FEATURED <span>WORKS</span>
      </h2>

      <div className="app__portofolio-filter">
        {["All", "Web App", "Nuget", "Mobile App", "Api"].map((item, index) => (
          <div
            key={item + index}
            onClick={() => handleProjectFilter(item)}
            className={`app__portofolio-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__portofolio-portofolio"
      >
        {filterProject.map((portofolio, index) => (
          <div className="app__portofolio-item app__flex" key={portofolio._id}>
            <div className="app__portofolio-img app__flex">
              <img src={portofolio.imgUrl} alt={portofolio} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__portofolio-hover app__flex"
              >
                <a
                  href={portofolio.projectLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
                <a href={portofolio.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className="app__portofolio-content app__flex">
              <h4 className="bold-text">{portofolio.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {portofolio.description}
              </p>

              <div className="app__portofolio-tag app__flex">
                <p className="p-text">{portofolio.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Portofolio, "app__portofolios"),
  "portofolio",
  "app__primarybg"
);
