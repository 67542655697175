import React from "react";
import { BsYoutube, BsWhatsapp } from "react-icons/bs";
import { FaGithub, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import "./SocialMedia.scss";

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://www.linkedin.com/in/gertian-mici-2102">
      <div>
        <FaLinkedinIn />
      </div>
    </a>
    <a href="https://www.youtube.com/channel/UC5dUMasCAjPZSXk0YsWM6sw">
      <div>
        <BsYoutube />
      </div>
    </a>
    <a href="https://wa.link/onrx9m">
      <div>
        <BsWhatsapp />
      </div>
    </a>
    <a href="https://github.com/GertianMici">
      <div>
        <FaGithub />
      </div>
    </a>   
    <a href="https://instagram.com/_u/gent.mici/">
      <div>
        <FaInstagram />
      </div>
    </a>
  </div>
);

export default SocialMedia;
