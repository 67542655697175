import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ReactTooltip from "react-tooltip";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Experiences.scss";

const Experiences = () => {
  const [experiences, setExperiences] = useState([]);
  const [tooltip, showTooltip] = useState(true);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const allExperiences = [
      {
        _id: 1,
        order: 1,
        company: "Qbrick",
        desc:
          "Demonstrated expertise in revitalising and optimising an event-driven architecture backend, overcoming memory allocation issues and performance bottlenecks." +
          "\nOptimised database read speed through query optimisation, resulting in a 50% reduction in response times." +
          "\nProactively identified areas for improvement and implemented best practices",
        name: "Senior Software Engineer",
        nameId: "software-engineer-qbrick",
        year: "09/2023 - current",
        _type: "experiences",
      },
      {
        _id: 2,
        order: 2,
        company: "Lufthansa Industry Solutions",
        desc:
          "Acted as lead developer and maintained a positive relationship with the client, resulting in earning the trust and decision-making credibility of the client." +
          "\nLed a team of software engineers to develop an enterprise navigation app backend that connects mobile devices to car navigation systems (IVI) for route sharing, reward generation, and the ability to save favorite locations and user vehicles." +
          "\nCreated the software architecture utilizing microservices, .NET, Redis, PostgreSQL, MongoDB, gRPC, and Kafka for event communication." +
          "\nEnsured the app performed seamlessly with over 70,000+ users over 2 days, with no delays or crashes." +
          "\nIndependently completed multiple microservices prior to the integration of additional team members." +
          "\nFacilitated knowledge transfer and provided guidance to team members on industry best practices." +
          "\nConsistently delivered projects on time and to the highest quality standards, resulting in an exceptional user experience." +
          '\nConsidered a "rockstar" member of the team, delivering projects quickly and at a high level of quality.',
        name: "Software Engineer, Development Team Lead",
        nameId: "software-engineer-lhind",
        year: "06/2022 - 09/2023",
        _type: "experiences",
      },
      {
        _id: 3,
        order: 3,
        company: "S2 Global, An OSI Systems Company",
        desc:
          "Working with a team of 4 developers in designing and developing high availability cloud services and platforms." +
          "\nMaintaining an ongoing Learning Management System, developed a new SSO application using Identity Server 4, and integrating other company software with the new Identity Application using Authorization Code Flow with Proof Key for Code Exchange (PKCE)." +
          "\nAdded support for multiple languages for Identity Application using .NET Localization." +
          "\nAdded RabbitMQ publish/subscribe pattern for exchanging messages between application.",
        name: "Software Engineer",
        nameId: "software-engineer-s2global",
        year: "03/2021 - 06/2022",
        _type: "experiences",
      },
      {
        _id: 4,
        order: 4,
        company: "Soft & Solution",
        desc: "Focused on developing Web Applications as a Full Stack Developer. I worked with different technologies and with a team of 4 developers, handling tasks and projects with accuracy and efficiency.",
        name: "Software Developer",
        nameId: "software-developer-ss",
        year: "02/2020 - 03/2021",
        _type: "experiences",
      },
      {
        _id: 5,
        order: 5,
        company: "Soft & Solution",
        desc: "I was part of a team with 5 other developers. Our main responsibilities were to design and develop razor views for our .NET web applications, maintain and create new web pages using WordPress",
        name: "Front-End Developer",
        nameId: "frontend-developer-ss",
        year: "09/2019 - 02/2020",
        _type: "experiences",
      },
      {
        _id: 6,
        order: 6,
        company: "Soft & Solution",
        desc: "Worked with Software Development department to identify and fix some existing issues on web based applications. I was involved in pair programming with different colleges in the Software department to get and share knowledge with more experienced developers on the team.",
        name: "Intern",
        nameId: "intern-ss",
        year: "08/2019 - 09/2019",
        _type: "experiences",
      },
    ];

    setExperiences(allExperiences);

    const allTestimonials = [
      {
        _id: 1,
        order: 1,
        name: "Z. Brahimaj",
        imgurl: images.linkedin,
        detail:
          '"Gertian has proven to be a great asset to our team, although in a relatively short period of time. Anyone would be lucky to have him on their team, as a developer and as a person!"',
        organisation: "Linkedin",
        _type: "testimonials",
      },
      {
        _id: 2,
        order: 2,
        name: "E. Londo",
        imgurl: images.linkedin,
        detail:
          '"Couldn\'t be more enthusiastic about recommending Gertian! His dedication, expertise, and collaborative spirit are an invaluable asset to any team. A true professional and a pleasure to work alongside."',
        organisation: "Linkedin",
        _type: "testimonials",
      },
      {
        _id: 3,
        order: 3,
        name: "A. Boci",
        imgurl: images.linkedin,
        detail:
          "\"'Rare talent' is the phrase that comes to mind when I think about Gertian. I had the pleasure of working with Gerti at the Soft&Solution Company, collaborating on several project teams. He is one of the best among all people I have ever met. Working with Gertian I found him as a person with great recognition and deep experience of IT solutions. Ambitious employee with boundless energy. Has strong organizational skills.\"",
        organisation: "Linkedin",
        _type: "testimonials",
      },
      {
        _id: 4,
        order: 4,
        name: "E. Hoxha",
        imgurl: images.linkedin,
        detail:
          '"I have worked on many group projects during these last 5 years, but Gertian is among the best project partners I have ever had. We have joined hands on several projects, and his dedication, correctness and problem-solving skills have been the deciding factor in the success of each one of them. I highly recommend Gertian to any person looking for a software engineer. He is a positive, patient and multitasking person. He has managed to study and also work full-time since he was in 2nd year of Bachelor and yet, he managed to do better than many of us. Gertian--with his profound understanding in every situation and a warm everlasting smile--would be an appreciated member of any team."',
        organisation: "Linkedin",
        _type: "testimonials",
      },
      {
        _id: 5,
        order: 5,
        name: "L. Bajraktari",
        imgurl: images.linkedin,
        detail:
          '"There is no better colleague than Gertian. He is one of the most dedicated professionals I\'ve worked with and is willing to put that extra help whenever you need it. His expertise as a developer is considerable, and it helped our team come up with more efficient solutions on different projects. His contribution is valuable to the side, and I highly recommend Gertian and would love to work with him again."',
        organisation: "Linkedin",
        _type: "testimonials",
      },
      {
        _id: 6,
        order: 6,
        name: "D. Aubert",
        imgurl: images.upwork,
        detail:
          '"Worked very quickly and efficiently to solve my problem. Communicated frequently. Would definitely recommend Gertian."',
        organisation: "Upwork Client",
        _type: "testimonials",
      },
      {
        _id: 7,
        order: 7,
        name: "D. Aubert",
        imgurl: images.upwork,
        detail:
          '"Gertian was very responsive, clear, and quick. We had an initial call and exchanged several messages up front. As soon as he had everything he needed, he did the work very efficiently. I would highly recommend!"',
        organisation: "Upwork Client",
        _type: "testimonials",
      },
    ];

    setTestimonials(allTestimonials);
  }, []);

  return (
    <>
      <h2 className="head-text">Testimonials & Experiences</h2>
      <div className="app__experiences-container">
        <motion.div className="app__experiences-list">
          <>
            {testimonials.length > 0 && (
              <>
                <div className="app__testimonials-item app__flex">
                  <div className="app__testimonials-content">
                    <p className="p-text">
                      <i className="italic-color">
                        {testimonials[currentIndex].detail}
                      </i>
                    </p>
                    <div>
                      <h4 className="bold-text">
                        {testimonials[currentIndex].name}
                      </h4>
                      <h5 className="p-text">
                        {testimonials[currentIndex].organisation}
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="app__testimonials-btns app__flex">
                  <div
                    className="app__flex"
                    onClick={() =>
                      handleClick(
                        currentIndex === 0
                          ? testimonials.length - 1
                          : currentIndex - 1
                      )
                    }
                  >
                    <HiChevronLeft />
                  </div>

                  <div
                    className="app__flex"
                    onClick={() =>
                      handleClick(
                        currentIndex === testimonials.length - 1
                          ? 0
                          : currentIndex + 1
                      )
                    }
                  >
                    <HiChevronRight />
                  </div>
                </div>
              </>
            )}
          </>
        </motion.div>
        <div className="app__experiences-exp">
          {experiences.map((experience) => (
            <motion.div
              className="app__experiences-exp-item"
              key={experience.nameId}
            >
              <div className="app__experiences-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__experiences-exp-works">
                <>
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__experiences-exp-portofolio"
                    data-tip
                    onMouseEnter={() => showTooltip(true)}
                    onMouseLeave={() => {
                      showTooltip(false);
                      setTimeout(() => showTooltip(true), 50);
                    }}
                    data-for={experience.nameId}
                    key={experience.nameId}
                  >
                    <h4 className="bold-text">{experience.name}</h4>
                    <p className="p-text">{experience.company}</p>
                  </motion.div>
                  <>
                    {tooltip && (
                      <ReactTooltip
                        id={experience.nameId}
                        effect="solid"
                        arrowColor="#fff"
                        className="experiences-tooltip"
                      >
                        {experience.desc}
                      </ReactTooltip>
                    )}
                  </>
                </>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Experiences, "app__experiences"),
  "experiences",
  "app__whitebg"
);
