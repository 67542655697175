import React from "react";
import PropTypes from "prop-types";
import "./Progress.scss";

const ProgressBar = (props) => {
  const { value, max, progressName, progressValue } = props;

  return (
    <div className="progress-container">
      <div className="clearfix">
        <div className="p-text">{progressName}</div>
        <div className="p-text">{progressValue}</div>
      </div>

      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: value + "%" }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax={max}
        ></div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  progressName: PropTypes.string,
  progressValue: PropTypes.string,
};

ProgressBar.defaitlProps = {
  max: 100,
};

export default ProgressBar;
