import api from '../assets/images/api.png';
import css from '../assets/images/css.png';
import git from '../assets/images/git.png';
import html from '../assets/images/html.png';
import javascript from '../assets/images/javascript.png';
import react from '../assets/images/react.png';
import typescript from '../assets/images/typescript.png';
import vue from '../assets/images/vue.png';
import email from '../assets/images/email.png';
import phone from '../assets/images/phone.png';


import profile from '../assets/images/profile.png';
import circle from '../assets/images/circle.svg';
import logo from '../assets/images/logo.png';

import webApp from '../assets/images/WebApp.jpg';
import mobileApp from '../assets/images/MobileApp.jpg';
import ecommerce from '../assets/images/ecommerce.jpg';
import identity from '../assets/images/identity.jpg';
import nuget from '../assets/images/nuget.png';

import ndg from '../assets/images/NDG.jpg';
import cybrary from '../assets/images/cybrary.jpg';
import microsoft from '../assets/images/microsoft.jpg';

import upwork from '../assets/images/upwork.png';
import linkedin from '../assets/images/linkedin.png';



export default {
  api,
  css,
  email,
  git,
  html,
  javascript,
  react,
  typescript,
  vue,
  phone,
  profile,
  circle,
  logo,
  webApp,
  mobileApp,
  ecommerce,
  identity,
  nuget,
  ndg,
  cybrary,
  microsoft,
  upwork,
  linkedin,
};
