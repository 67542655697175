import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Home.scss";
import TypeWriterEffect from "react-typewriter-effect";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Home = () => (
  <div className="app__home app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__home-info"
    >
      <div className="app__home-badge">
        <div className="tag-cmp app__flex">
          <TypeWriterEffect
            textStyle={{
              fontFamily: '"DM Sans", sans-serif',
              fontWeight: 400,
              fontSize: "1em",
            }}
            startDelay={2000}
            cursorColor="#3F3D56"
            multiText={[
              ".NET ENIGINEER",
              "REACT | ANGULAR",
              "Flutter",
              "AWS | AZURE | DOCKER",
            ]}
            multiTextDelay={40}
            typeSpeed={100}
            multiTextLoop={true}
          />
        </div>
        <div className="badge-cmp app__flex">
          <span>👋</span>
          <div>
            <p className="p-text">HELLO MY NAME IS</p>
            <h1 className="head-text">GERTIAN</h1>
          </div>
        </div>
        <div className="badge-cmp app__flex">
          <div>
            <p className="p-text" style={{ fontSize: "1rem" }}>
              <i>
                {" "}
                <q>
                  The future belongs to those who believe in the beauty of their
                  dreams
                </q>
              </i>
            </p>
          </div>
        </div>
      </div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__home-img"
      >
        <motion.img src={images.profile} alt="profile" />
      </motion.div>
    </motion.div>
  </div>
);

export default AppWrap(Home, "home");
