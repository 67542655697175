import React from 'react';

import { About, Contact, Home, Experiences, Certificates, Portofolio } from './contents';
import { Navbar } from './components';
import './App.scss';

const App = () => (
  <div className="app">
    <Navbar />
    <Home />
    <About />
    <Portofolio />
    <Experiences />
    <Certificates />
    <Contact />
  </div>
);

export default App;
